$auth-primary-color: #4205dd;

$auth-background-color-primary: #ffffff;
$auth-background-color-secondary: #f6f6f6;
$auth-background-color-tertiary: #f3f3f3;

$auth-normal-font-size: 1rem;
$auth-normal-font-color: #666666;

$auth-large-font-size: 1.5rem;
$auth-large-font-color: #000000;

$auth-small-font-size: 0.875rem;
$auth-small-font-color: #b0b0b0;

$header-footer-height: 64px;

$spacing: 1.5rem;
$spacing-large: 2.2rem;

$divider-color: #e8e8e8;

$auth-error-color: #dd1e38;
$auth-on-error-color: #ffffff;

$auth-warning-color: #f59d36;
$auth-on-warning-color: #ffffff;

$auth-info-color: #7086ff;
$auth-on-info-color: #ffffff;

$auth-success-color: #0cca8f;
$auth-on-success-color: #ffffff;
