/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.
*/
@import './_variables.scss';

/* Layout */
.layout.layout-main {
  > .ant-layout-header {
    width: 100%;
    position: fixed;
    z-index: 3;

    padding: 0 0;
    height: $navigation-height;
    border-bottom: 1px solid #ccc;

    display: flex;
    align-items: center;

    .ant-menu-horizontal {
      border-bottom-width: 0;
      line-height: ($navigation-height - 3px);
      display: flex;
      align-items: center;
      flex: 1;
    }
  }
}

.container {
  max-width: 1080px;
  width: 100%;
  display: block;
  margin: auto;

  // Analytics should be wider by default
  &.analytics {
    max-width: 1300px;
  }
}

h4,
h3,
h2,
h1 {
  small {
    font-weight: normal;
  }
  svg {
    width: 38px;
    height: 38px;
    margin-bottom: 8px;
    vertical-align: middle;
  }
}

.img-responsive {
  width: 100%;
  height: auto;
}

/* alternative selectable card */
.card-selectable.ant-card {
  border-radius: 2px;

  li {
    margin: 0 0 5px 0;
  }
  &:hover {
    cursor: pointer;
    background: $card-hover-color;
  }

  &.selected-card {
    background: $card-hover-color;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    border-color: transparent;
  }

  .ant-card-head {
    background: inherit;
  }
}

.card-not-selectable.ant-card {
  &:hover {
    cursor: default;
    box-shadow: inherit;
    border-color: $card-border-color;
  }
}

.non-clickable.ant-tag {
  cursor: default;

  &:hover {
    opacity: 1;
  }
}

.ant-tooltip {
  a {
    color: $color-gray-lighter;

    &:hover {
      color: $color-white;
      text-decoration: underline;
    }
  }
}
