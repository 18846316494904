@import '~styles/_variables.scss';

.layout-main > .ant-layout-footer {
  border-top: 1px solid #ccc;
  background-color: #f8f8f8;
  padding: 0 0;
  z-index: 2;

  .list-horizontal {
    > * {
      margin-right: 22px;
    }
  }

  .divider-horizontal {
    border-top-color: #ccc;
  }
  .ant-divider {
    background: #ccc;
  }

  .footer-top-section {
    padding: 40px 20px 15px 20px;

    h4.title {
      text-transform: uppercase;
      color: $color-blue;
      font-weight: bold;
    }

    a {
      color: #666;
    }
  }

  .footer-bottom-section {
    padding: 15px 0;
  }

  .ant-row {
    margin-top: 0;
  }

  .support,
  .support:hover,
  .support:active {
    border: 0;
    background-color: transparent;
    cursor: auto;

    .ant-card-head,
    .ant-card-body {
      border: 0;
      background-color: transparent;
      padding: 0;
    }

    .ant-card-head-title {
      text-transform: uppercase;
      color: $color-blue;
      font-weight: bold;
      font-size: $font-size-md;
      line-height: 21px;
    }
  }
}
