.auth-divider {
  position: relative;
  text-align: center;
  margin: $spacing 0;

  &-text {
    position: relative;
    padding: 0 $spacing;
    background-color: $auth-background-color-primary;
    z-index: 1;
  }

  &::before {
    height: 1px;
    width: 100%;
    border: 1px solid $divider-color;
    z-index: 0;

    @include center;
  }
}
