$icomoon-font-path: 'fonts' !default;

$icon-looks_two: '\e929';
$icon-looks_one: '\e92a';
$icon-looks_3: '\e92b';
$icon-format_list_numbered: '\e92c';
$icon-format_list_bulleted: '\e92d';
$icon-format_color_text: '\e92e';
$icon-palette: '\e92f';
$icon-vertical_align_top: '\e930';
$icon-vertical_align_center: '\e931';
$icon-vertical_align_bottom: '\e932';
$icon-format-italic: '\e933';
$icon-format-bold: '\e934';
$icon-align-right: '\e935';
$icon-align-left: '\e936';
$icon-align-center: '\e937';
$icon-format-underline: '\e938';
$icon-layout-hhalf-top: '\e939';
$icon-layout-hhalf: '\e93a';
$icon-layout-hhalf-bottom: '\e93b';
$icon-layout-vhalf-right: '\e93c';
$icon-layout-vhalf: '\e93d';
$icon-layout-vhalf-left: '\e93e';
$icon-layout-quarter: '\e93f';
$icon-layout-full: '\e940';
$icon-envelope: '\e914';
$icon-wallet: '\e915';
$icon-calendar-o: '\e916';
$icon-organization-o: '\e917';
$icon-location-o: '\e918';
$icon-hosting-o: '\e919';
$icon-cogs-o: '\e91a';
$icon-labs-o: '\e91b';
$icon-features-o: '\e91c';
$icon-device-o: '\e91d';
$icon-wi-fi-o: '\e91e';
$icon-insights-o: '\e91f';
$icon-hour-o: '\e920';
$icon-becon-o: '\e921';
$icon-integrations-o: '\e922';
$icon-envelope-o: '\e923';
$icon-cart-o: '\e924';
$icon-wallet-o: '\e925';
$icon-card-o: '\e926';
$icon-rooms-o: '\e927';
$icon-customcontent: '\e928';
$icon-cart: '\e913';
$icon-card: '\e900';
$icon-image-alt: '\e905';
$icon-becon: '\e907';
$icon-insights: '\e909';
$icon-device: '\e910';
$icon-location: '\e911';
$icon-hour: '\e912';
$icon-integrations: '\e90e';
$icon-linux: '\e90c';
$icon-logo: '\e90d';
$icon-features: '\e901';
$icon-wi-fi: '\e902';
$icon-rooms: '\e903';
$icon-organization: '\e904';
$icon-labs: '\e906';
$icon-hosting: '\e908';
$icon-cogs: '\e90a';
$icon-calendar: '\e90b';
$icon-customcontent-o: '\e90f';
