@import './variables';

@mixin center {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin display-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin respond($breakpoint) {
  @if $breakpoint == tablet {
    @media (max-width: 1000px) {
      @content;
    }
  }
  @if $breakpoint == mobile {
    @media (max-width: 600px) {
      @content;
    }
  }
}

@mixin main-content-wrapper {
  z-index: 10;
  position: relative;
  display: flex;
  flex: 1;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: $auth-background-color-tertiary;

  @include respond(mobile) {
    background-color: $auth-background-color-primary;
  }
}

@mixin main-content($bg: none) {
  z-index: 10;
  width: 100%;

  padding: $spacing-large;

  & > * {
    margin: 0 auto;
    width: 90%;
    max-width: 31rem;
    min-width: 10rem;

    @if $bg != none {
      background-color: $bg;
    }
  }

  @include respond(mobile) {
    padding: $spacing 0;
  }
}

@mixin password-reset-actions {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: $spacing-large 0;
  padding-bottom: 0;
}

@mixin mobile-form {
  max-height: 100%;
  min-width: unset;
  box-shadow: none;
  width: 100%;
  padding: $spacing;
}
