$divider-color: #e9e9e9;

.divider {
  display: inline-block;

  &.divider-horizontal {
    width: 100%;
    height: 1px;
    border-top: 1px solid $divider-color;
  }

  &.divider-vertical {
    height: 100%;
    width: 1px;
    border-left: 1px solid $divider-color;
  }
}
