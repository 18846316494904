#password-strength {
  display: none;
  align-items: center;
  justify-content: space-between;

  margin-top: 0.5rem;

  .password-strength-container {
    display: flex;
    align-items: center;
    &-bar {
      width: 64px;
      height: 4px;

      visibility: hidden;

      margin-right: 0.2rem;
    }
  }

  &-bar-1 {
    background: #eb5757;
  }

  &-bar-2 {
    background: #f2c94c;
  }

  &-bar-3 {
    background: #0cca8f;
  }

  &-text {
    margin-left: 0.3rem;
    padding: 0;

    font-size: $auth-small-font-size;
    color: $auth-normal-font-color;
  }
}
