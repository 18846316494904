@import './fonts/variables.scss';
@import './fonts/style.scss';

$icomoon-font-path: 'fonts' !default;

svg.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  vertical-align: text-bottom;
  font-size: 1.3em;

  &.icon-circle {
    border: 1px solid gray;
    border-radius: 50% 50%;
    padding: 10% 10%;
  }
}

.icon-home {
  font-size: 2.7rem;
  height: 2.7rem;
  &:before {
    display: block;
  }
}

.icon:not[svg] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'joan-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-content-picker {
  .ant-card-cover {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem 0;
  }

  .ant-card-body {
    padding: 0 0 1.5rem 0;
  }

  img {
    width: 4.5rem;
    height: 4.5rem;
  }
}
