// design system based on https://www.figma.com/file/NpZQ7huYytI4J7CtDlyz1q/WIP?node-id=0%3A1
// TODO: add proper scss variables
& {
  font-size: 16px;

  h1 {
    font-size: 80px;
  }

  h2 {
    font-size: 64px;
  }

  h3 {
    font-size: 48px;
  }

  h4 {
    font-size: 36px;
  }

  h5 {
    font-size: 24px;
  }

  h6 {
    font-size: 20px;
  }

  .text-sm {
    font-size: 12px;
  }

  // antd overrides
  .ant-modal-body {
    font-size: 16px;
  }

  .ant-form-extra {
    font-size: 12px;
  }

}
