.auth-pass-reset-done {
  @include main-content-wrapper;

  &-content {
    @include main-content;

    &-title {
      padding: $spacing-large;
      padding-left: 0;
      padding-top: 0;
    }

    .auth-inline-link {
      font-weight: 900;
    }
  }

  &-actions {
    @include password-reset-actions;
  }

  @include respond(mobile) {
    &-content {
      text-align: center;

      &-title {
        text-align: center;
        padding-right: 0;
      }
    }

    &-actions {
      justify-content: center;
    }
  }

  &-actions {
    .joan-ui-button.link {
      text-transform: uppercase;
      letter-spacing: 0.07em;
      padding: 1rem 0;
    }
  }
}
