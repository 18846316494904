@import './_base.scss';
@import './_variables.scss';
@import './_utils.scss';
@import './_form.scss';
@import './_list.scss';
@import '~@joan/joan-ui/dist/index.css';
@import './portal-auth/base.scss';

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-weight: $font-weight-base;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
.layout {
  min-height: 100%;
}

.login {
  background-color: #efefef;
  min-height: auto;
  padding-bottom: 2rem;
  .ant-layout-content {
    margin-bottom: 100px;
  }
  .ant-card {
    background: $card-hover-color;

    .ant-card-head {
      background: $card-hover-color;
      border-bottom: 1px solid $card-border-color;
      margin-bottom: 0px;
    }

    .ant-card-body {
      min-height: 470px;
    }
  }
  .ant-form-item {
    margin-bottom: 5px !important;
  }
  button {
    &.ant-btn-lg {
      width: 180px;
      font-size: 14px;
      margin-top: 30px;
    }
  }

  .inverted {
    background: $color-blue !important;
    color: white;

    a {
      color: white;
    }

    &.ant-card:hover > * {
      background: inherit;
    }

    button {
      border-color: white;
    }

    h3 {
      color: inherit;
    }

    label.ant-form-item-required {
      color: white;
    }

    .ant-card-head {
      background: inherit;
    }
  }

  .background-clouds {
    position: absolute;
    width: 1080px;
    left: -10%;
    top: -5%;
  }

  .top {
    z-index: 3;
  }
}

$account-height: 950px;
$account-background-color: $color-gray;

.account {
  padding-bottom: 0;

  .ant-layout-header {
    border-bottom: none !important;
  }

  .ant-layout-content {
    margin-bottom: 0;
    margin-top: $navigation-height;
    background: $account-background-color;

    .info {
      height: $account-height;

      .layer {
        background: rgba(0, 34, 224, 0.8);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        padding-top: 100px;

        .content {
          width: 350px;
          color: $color-white;

          h1 {
            color: $color-white;
          }
        }
      }
    }

    > .form {
      margin-top: 64px;
      margin-bottom: 32px;
    }

    .form {
      display: flex;
      justify-content: center;

      .content {
        width: 350px;

        .signin,
        .signup {
          display: flex;
          flex-direction: column;

          .title {
            text-align: center;
            margin-bottom: 20px;
            font-size: $font-size-lg;
            color: $color-black;
            font-weight: bold;
          }

          .fields {
            border: 1px solid $color-black;
            padding: 25px 15px;

            .ant-form-item {
              &:first-of-type {
                .ant-form-item-label {
                  margin-top: 0;
                }
              }

              .ant-form-item-label {
                line-height: 30px;
                margin-top: 10px;
                font-weight: bold;

                label:after {
                  content: '';
                }
              }

              .ant-input-lg {
                border: 1px solid $color-black;
              }
            }

            .forgot-password {
              margin-top: 15px;
              text-align: right;

              a {
                font-size: $font-size-sm;
                color: $color-gray-dark;
                text-decoration: underline;
              }
            }

            .terms-of-use {
              width: 100%;
              color: $color-gray-dark;
              padding: 15px 0 0 0;

              a {
                color: $color-gray-dark;
                text-decoration: underline;
              }
            }

            button {
              &.ant-btn-lg {
                width: 100%;
                font-size: $font-size-md;
                text-transform: uppercase;
                margin-top: 15px;
              }

              &.sso-button {
                text-transform: none;
                background: $account-background-color;
                color: $color-black;
                border: 1px solid $color-black;

                &:first-of-type {
                  margin-top: 0;
                }

                img {
                  height: 20px;
                  position: relative;
                  top: -2px;
                  margin-right: 5px;
                }
              }
            }

            .divider {
              margin: 22px 0;
              border-top: 1px solid $color-black;
              position: relative;
              width: 100%;

              span {
                position: absolute;
                top: -22px;
                left: 50%;
                transform: translateX(-50%);
                padding: 10px;
                background: $account-background-color;
                color: $color-gray-dark;
              }
            }
          }

          .signup-link {
            text-align: center;
            margin-top: 20px;
            font-size: $font-size-sm;
            color: $color-gray-dark;

            a {
              color: $color-blue;
              text-decoration: underline;
            }
          }
        }

        .signup {
          button {
            &.sso-button {
              &:last-of-type {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.logo-menu {
  margin: 12px 24px 12px 20px;
  line-height: 1;

  display: flex;
  align-items: center;

  > img {
    width: 85px;
  }
}

.avatar-menu {
  > a {
    display: flex !important;
  }

  &:hover {
    path {
      fill: white !important;
    }
  }

  &.ant-menu-item .ant-avatar {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .anticon {
      margin-left: 5px;

      path {
        fill: white;
      }
    }
  }

  &.ant-menu-item {
    border-bottom-width: 0 !important;
  }
}

.ant-layout-sider {
  .ant-menu {
    height: 100%;
    padding-bottom: 40px;

    &-item {
      svg {
        transition: fill 0.3s;
        vertical-align: middle;
        margin-bottom: 4px;
        path {
          transition: fill 0.3s;
          fill: #222;
        }
      }
      &:hover {
        svg {
          path {
            fill: #0000fe;
          }
        }
      }

      &-selected {
        svg {
          path {
            fill: #0000fe;
          }
        }
      }
    }
  }

  .icon {
    padding-right: 8px;
  }
}

.container-flex {
  max-width: 1080px;
  display: flex;
  flex: 0 0 auto;
  margin: auto;
  justify-content: space-between;
}

// switch component inside menu
.menu-switch {
  padding: 16px 16px 16px 24px;
}

.btn-toolbar {
  display: block;
  margin-bottom: 14px;

  > button,
  > .btn-wrap {
    margin-right: 12px;
  }

  > .ant-badge {
    vertical-align: inherit;
  }
}

.with-pointer {
  .ant-table-tbody {
    > .ant-table-row {
      cursor: pointer;
    }
  }
}

.no-select-all {
  .ant-table-selection {
    display: none;
  }
}

p {
  padding: 5px 0 10px 0;
}

.with-border {
  border-bottom: 1px solid $card-border-color;
  padding: 5px 25px 25px 25px;
}

.ant-badge-count {
  p {
    padding: 0 0;
  }
}

h1 {
  font-size: $font-size-xxlg;
  margin-bottom: 8px;
}
h2 {
  font-size: $font-size-xlg;
  margin-bottom: 4px;
}
h3 {
  font-size: $font-size-lg;
}

.icon-jumbo,
svg.icon-jumbo,
.icon-jumbo > .ant-badge {
  font-size: 4.6rem;
}
img.icon-jumbo {
  height: 64px;
}

.jumbo {
  img {
    max-width: 10rem;
    max-height: 10rem;
    height: auto;
    width: auto;
  }
}

.layout-error {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30vmax;
  text-align: center;

  .icon-logo {
    font-size: 15vmin;
  }

  h4 {
    padding-top: 10px;
    font-size: 3vmin;
    color: $color-gray-dark;
  }
}

ul.errorlist {
  margin-top: 0.5rem;
}

/* icon with label */
.icon-label {
  display: inline-block;
  text-align: center;
  .icon {
    display: block;
  }
  label {
    display: block;
    font-size: $font-size-sm;
    text-transform: uppercase;
    color: $color-gray-dark;
  }
}

.link-plain,
.link-plain:active,
.link-plain:hover {
  color: inherit;
  text-decoration: none;
}

.badge-success {
  /*Workaround: antd since 3.20.26 does not render background if badge is not a number
    this solution mimic how antd is rendering the badge dot
  */
  .ant-scroll-number-custom-component {
    background-color: $color-green;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    color: #fff;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff;
  }
}

/* app loader */
.app-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  text-align: center;

  > div {
    img {
      height: 25vmin;
    }

    h4 {
      padding-top: 10px;
      font-size: 4vmin;
      color: $color-gray-dark;
    }
  }
}

/* animation effects */

// .slide-left {

// 	transform: translate3d(-200px,0,0);
//     opacity: 0;

//     &.slide-left-appear {
//         opacity: 1;
//         transform: translate3d(0,0,0);
//         transition: opacity 0.43s 0.6s, transform 0.4s 0.65s cubic-bezier(0, 0.88, 0.4, 0.93);
//     }
// }

.ant-carousel .slick-slide {
  text-align: center;
  height: 100%;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

// card with animated actions
.ant-card {
  &.ant-card-actions-slide-top {
    overflow: hidden;

    .ant-card-actions {
      position: absolute;
      width: 100%;
      top: 0;
      transition: transform 0.3s ease-in;
      transform: translateY(-100%);
    }

    &:hover .ant-card-actions {
      transform: translateY(0);
    }
  }
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
  > h1,
  h2,
  h3,
  h4 {
    flex-grow: 2;
  }
  > .heading-right {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    align-items: center;
  }
}

// make sure that antd icons are not bolded in heading elements
// our custom icons use thin lines, so bolded antd icons look out of place
h1,
h2,
h3,
h4,
h5,
h6 {
  .anticon {
    font-weight: normal;
  }
}

// bullet list
ul.list {
  list-style: circle;
}

// beta tags
.beta {
  text-transform: uppercase;
  &.ant-tag {
    background-color: #f50;
    border-color: #f50;
    color: #fff;
  }

  h1 & {
    vertical-align: middle;
  }
}

.joan-portal-home .mobile.notifications {
  display: none;
}

@media screen and (max-height: 999px) {
  .layout.login .container-fluid .content-main {
    display: flex;
    align-items: center;

    .info {
      max-height: 950px;
      min-height: 770px;
      height: auto;
    }

    .form {
      height: 100%;
    }
  }
}

@media screen and (max-width: 765px) {
  .login-left-panel,
  .register-left-panel {
    display: none;
  }

  .login-right-panel,
  .register-right-panel {
    flex: 1;
    padding: 2rem;
    width: 100%;
    height: 100% !important;

    .content {
      width: 100% !important;
    }
  }

  .account .ant-layout-content > .form {
    margin-top: 0;
    margin-bottom: 16px;
  }

  .footer {
    .container {
      .ant-row {
        .ant-col-4 {
          width: 50%;
        }

        .ant-col-8 {
          width: 100%;
          margin-top: 2rem;
        }

        .ant-col-16 {
          width: 100%;
        }
      }

      .footer-bottom-section {
        .ant-col-8 {
          margin-top: 0;
        }
      }
    }
  }

  .joan-portal-home {
    .ant-col-8 {
      width: 100%;
      margin-top: 1rem;
    }

    .mobile.notifications {
      display: block;
      margin: 1rem 0 0 0;

      .banner-base {
        line-height: 1.5;
        font-size: 0.9rem;
      }

      .banner-inner {
        display: flex;
        padding: 0.3rem;
        margin: 0;
        align-items: center;
        justify-content: center;
        width: 100%;

        .anticon {
          margin: 0;
        }

        .icon {
          flex-basis: 10%;
        }

        .text {
          flex-basis: 85%;
          width: 100%;
        }
      }
    }
  }
}

.joan-typography {
  @import './_typography.scss';
}
