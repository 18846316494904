/*
  This folder is almost completey detached from
  other CSS logic in preparation for the Portal
  Merger finishing. Once the whole Portal FE code
  is removed, we need to import a CCS reset
  stylesheet and recheck the elements
  etc. in case some details were missed.

  Some random padding or margin problems could
  surface since the CCS is everywhere at the
  moment.
*/
@import './variables';
@import './mixins';
@import './components/divider';
@import './components/password_strength';
@import './components/error_text';
@import './components/auth_background';
@import './pages/sign_in';
@import './pages/sign_up';
@import './pages/password_reset';
@import './pages/password_reset_done';
@import './pages/password_reset_complete';
@import './pages/password_reset_token';

.auth {
  z-index: -15; // Otherwise captcha hover doesn't work

  .grecaptcha-badge {
    z-index: 100;
  }

  background-color: $auth-background-color-primary;

  height: 100vh;
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: $auth-large-font-size;
    color: $auth-large-font-color;
    margin-bottom: 8px;
  }

  p {
    font-size: $auth-normal-font-size;
    color: $auth-normal-font-color;
  }

  .small-font {
    font-size: $auth-small-font-size;
    color: $auth-small-font-color;
  }

  a,
  .small-strong-font {
    font-size: $auth-small-font-size;
    color: $auth-large-font-color;
  }

  .auth-inline-link {
    font-size: $auth-normal-font-size;
    color: $auth-primary-color;
    display: inline;
  }

  ::selection {
    color: #ffffff;
    background: #6669d9;
  }

  &-header {
    padding-left: 44px;
    height: $header-footer-height;
    min-height: $header-footer-height;

    display: flex;
    justify-content: flex-start;

    &-logo {
      display: flex;
      align-items: center;

      > img {
        width: 85px;
      }
    }
  }

  &-footer {
    padding-left: 44px;
    position: relative;

    height: $header-footer-height;
    min-height: $header-footer-height;

    display: flex;
    align-items: center;
    justify-content: center;

    &-copyright {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);

      padding-left: 44px;
    }

    &-links {
      display: flex;
      align-items: center;

      &-link {
        margin: 0 1rem;
      }
    }
  }

  &-message {
    margin-bottom: $spacing;
    padding: $spacing;

    &-success {
      background-color: $auth-success-color;
      span {
        color: $auth-on-success-color;
      }
    }
    &-warning {
      background-color: $auth-warning-color;
      span {
        color: $auth-on-warning-color;
      }
    }
    &-error {
      background-color: $auth-error-color;
      span {
        color: $auth-on-error-color;
      }
    }
    &-info {
      background-color: $auth-info-color;
      span {
        color: $auth-on-info-color;
      }
    }
  }

  @include respond(tablet) {
    padding-left: 0;

    &-header,
    &-footer {
      padding-left: 44px;
    }

    &-footer {
      &-links {
        &-link {
          margin: 0 0.5rem;
        }
      }
    }
  }

  @include respond(mobile) {
    &-header,
    &-footer {
      padding-left: 0;
    }

    &-footer {
      &-copyright {
        display: none;
      }
      &-links {
        flex-wrap: wrap;
      }
    }

    &-header {
      margin: 0 auto;
    }
  }
}
