.auth-pass-reset {
  @include main-content-wrapper;

  &-content {
    @include main-content($auth-background-color-primary);

    &-title {
      padding: $spacing-large;
      padding-left: 0;
      padding-top: 0;
      background-color: transparent;
    }

    .auth-pass-reset-form {
      padding: $spacing-large;

      &-fields {
        form {
          display: flex;
          flex-direction: column;

          justify-content: stretch;

          .auth-pass-reset-form-fields-field {
            margin-bottom: $spacing;

            label {
              margin-bottom: calc($spacing / 2);
            }
          }
        }

        &-field {
          label {
            display: inline-block;
            font-weight: 900;
            color: $auth-normal-font-color;
            font-size: $auth-small-font-size;
          }
        }
      }
    }
  }

  &-actions {
    @include password-reset-actions;

    .joan-ui-button.link {
      text-transform: uppercase;
      letter-spacing: 0.07em;
      padding: 1rem 0;
    }
  }

  @include respond(mobile) {
    .auth-pass-reset-form {
      @include mobile-form;
    }

    &-content {
      &-title {
        text-align: center;
        padding-right: 0;
      }
    }
  }
}
