@import './_variables.scss';

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

.block {
  display: block;
}

.text-center {
  text-align: center;
}

.padding-left {
  padding-left: 24px;

  &-sm {
    padding-left: 12px;
  }
}

.padding-right {
  padding-right: 24px;

  &-small,&-sm {
    padding-right: 12px;
  }
}

.padding-top {
  padding-top: 24px;
}

.padding-top-sm {
  padding-top: 12px;
}

.padding-bottom {
  padding-bottom: 24px;

  &-small {
    padding-bottom: 12px;
  }
}

.padding-vertical {
  @extend .padding-top;
  @extend .padding-bottom;
}

.padding-horizontal {
  @extend .padding-left;
  @extend .padding-right;
}

.padding {
  @extend .padding-horizontal;
  @extend .padding-vertical;
}

/* margins */
.margin-left {
  margin-left: 24px;
}

.margin-right {
  margin-right: 24px;
}

.margin-top {
  margin-top: 24px;
}

.margin-bottom {
  margin-bottom: 24px;
}

.margin-vertical {
  @extend .margin-top;
  @extend .margin-bottom;
}

.margin-horizontal {
  @extend .margin-left;
  @extend .margin-right;
}

.margin {
  @extend .margin-horizontal;
  @extend .margin-vertical;
}

.text-md {
  font-size: 1.1rem;
}

.text-lg {
  font-size: $font-size-lg;
}

.text-xlg {
  font-size: $font-size-xlg;
}

.text-sm {
  font-size: $font-size-sm;
}

.text-xs {
  font-size: $font-size-xs;
}

.text-base {
  font-size: $font-size-base;
}

.text-uppercase {
  text-transform: uppercase;

  // antd has explicit text-transform on button element
  button {
    text-transform: uppercase;
  }
}

.mute,
.text-mute {
  color: $color-gray-lighter;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/* color variants */
.text-success {
  color: $color-green;
}

.text-danger {
  color: $color-light-red;
}

.display-inline {
  display: inline;
}

.spin {
  animation: loadingCircle 1s infinite linear;
}

/* rotations */
.rotate-90 {
  transform: rotate(90deg);
}

.animate-size {
  transition: width 0.5s ease-out, height 0.5s ease-out;
}

/* colors */
.color-danger {
  color: $color-light-red;
}

.color-warn {
  color: $color-light-orange;
}

.text-button {
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
  appearance: none;
  color: $color-blue;
}
