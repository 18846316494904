.auth-signin {
  display: flex;
  flex: 1;

  align-items: stretch;
  justify-content: center;

  padding-left: 44px;

  &-left {
    position: relative;

    flex: 1;

    @include display-center;

    &-image {
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100%;

      &-background {
        width: 100%;
        height: 100%;
        background-size: cover;
      }
    }

    &-textbox {
      width: 90%;
      max-width: 31rem;
      min-width: 10rem;

      max-height: 90%;
      overflow: auto;
      overflow-x: hidden;

      z-index: 10;

      background-color: $auth-background-color-primary;

      padding: $spacing-large;

      margin-bottom: 15%;
    }
  }

  &-middle {
    width: 3rem;
  }

  &-right {
    flex: 1;

    @include display-center;

    background-color: $auth-background-color-secondary;

    padding: $spacing-large;

    .auth-signin-form {
      max-width: 31rem;
      min-width: 30rem;

      background-color: $auth-background-color-primary;

      display: flex;
      justify-content: stretch;

      flex-direction: column;

      padding: $spacing-large;

      &-providers {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: stretch;

        & > *:not(.auth-signin-form-fields-links) {
          margin-bottom: $spacing;
        }
      }

      &-fields {
        form {
          display: flex;
          flex-direction: column;

          justify-content: stretch;
        }

        &-field {
          margin-bottom: $spacing;
        }

        & > * {
          width: 100%;
        }

        &-links {
          display: flex;
          justify-content: center;

          .small-font {
            margin: 0 1rem;
          }

          &-link {
            color: $auth-primary-color;
          }
        }
      }
    }
  }

  @include respond(tablet) {
    padding-left: 0;

    &-left {
      display: none;
    }

    &-middle {
      display: none;
    }
  }

  @include respond(mobile) {
    &-right {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;

      background-color: $auth-background-color-primary;

      align-items: start;

      .auth-signin-form {
        @include mobile-form;
      }
    }
  }
}
