@import 'variables';

@font-face {
  font-family: 'joan-icons';
  src: url('#{$icomoon-font-path}/joan-icons.ttf?t5mcr8') format('truetype'),
    url('#{$icomoon-font-path}/joan-icons.woff?t5mcr8') format('woff'),
    url('#{$icomoon-font-path}/joan-icons.svg?t5mcr8#joan-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'joan-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-looks_two {
  &:before {
    content: $icon-looks_two;
  }
}
.icon-looks_one {
  &:before {
    content: $icon-looks_one;
  }
}
.icon-looks_3 {
  &:before {
    content: $icon-looks_3;
  }
}
.icon-format_list_numbered {
  &:before {
    content: $icon-format_list_numbered;
  }
}
.icon-format_list_bulleted {
  &:before {
    content: $icon-format_list_bulleted;
  }
}
.icon-format_color_text {
  &:before {
    content: $icon-format_color_text;
  }
}
.icon-palette {
  &:before {
    content: $icon-palette;
  }
}
.icon-vertical_align_top {
  &:before {
    content: $icon-vertical_align_top;
  }
}
.icon-vertical_align_center {
  &:before {
    content: $icon-vertical_align_center;
  }
}
.icon-vertical_align_bottom {
  &:before {
    content: $icon-vertical_align_bottom;
  }
}
.icon-format-italic {
  &:before {
    content: $icon-format-italic;
  }
}
.icon-format-bold {
  &:before {
    content: $icon-format-bold;
  }
}
.icon-align-right {
  &:before {
    content: $icon-align-right;
  }
}
.icon-align-left {
  &:before {
    content: $icon-align-left;
  }
}
.icon-align-center {
  &:before {
    content: $icon-align-center;
  }
}
.icon-format-underline {
  &:before {
    content: $icon-format-underline;
  }
}
.icon-layout-hhalf-top {
  &:before {
    content: $icon-layout-hhalf-top;
  }
}
.icon-layout-hhalf {
  &:before {
    content: $icon-layout-hhalf;
  }
}
.icon-layout-hhalf-bottom {
  &:before {
    content: $icon-layout-hhalf-bottom;
  }
}
.icon-layout-vhalf-right {
  &:before {
    content: $icon-layout-vhalf-right;
  }
}
.icon-layout-vhalf {
  &:before {
    content: $icon-layout-vhalf;
  }
}
.icon-layout-vhalf-left {
  &:before {
    content: $icon-layout-vhalf-left;
  }
}
.icon-layout-quarter {
  &:before {
    content: $icon-layout-quarter;
  }
}
.icon-layout-full {
  &:before {
    content: $icon-layout-full;
  }
}
.icon-envelope {
  &:before {
    content: $icon-envelope;
  }
}
.icon-wallet {
  &:before {
    content: $icon-wallet;
  }
}
.icon-calendar-o {
  &:before {
    content: $icon-calendar-o;
  }
}
.icon-organization-o {
  &:before {
    content: $icon-organization-o;
  }
}
.icon-location-o {
  &:before {
    content: $icon-location-o;
  }
}
.icon-hosting-o {
  &:before {
    content: $icon-hosting-o;
  }
}
.icon-cogs-o {
  &:before {
    content: $icon-cogs-o;
  }
}
.icon-labs-o {
  &:before {
    content: $icon-labs-o;
  }
}
.icon-features-o {
  &:before {
    content: $icon-features-o;
  }
}
.icon-device-o {
  &:before {
    content: $icon-device-o;
  }
}
.icon-wi-fi-o {
  &:before {
    content: $icon-wi-fi-o;
  }
}
.icon-insights-o {
  &:before {
    content: $icon-insights-o;
  }
}
.icon-hour-o {
  &:before {
    content: $icon-hour-o;
  }
}
.icon-becon-o {
  &:before {
    content: $icon-becon-o;
  }
}
.icon-integrations-o {
  &:before {
    content: $icon-integrations-o;
  }
}
.icon-envelope-o {
  &:before {
    content: $icon-envelope-o;
  }
}
.icon-cart-o {
  &:before {
    content: $icon-cart-o;
  }
}
.icon-wallet-o {
  &:before {
    content: $icon-wallet-o;
  }
}
.icon-card-o {
  &:before {
    content: $icon-card-o;
  }
}
.icon-rooms-o {
  &:before {
    content: $icon-rooms-o;
  }
}
.icon-customcontent {
  &:before {
    content: $icon-customcontent;
  }
}
.icon-cart {
  &:before {
    content: $icon-cart;
  }
}
.icon-card {
  &:before {
    content: $icon-card;
  }
}
.icon-image-alt {
  &:before {
    content: $icon-image-alt;
  }
}
.icon-becon {
  &:before {
    content: $icon-becon;
  }
}
.icon-insights {
  &:before {
    content: $icon-insights;
  }
}
.icon-device {
  &:before {
    content: $icon-device;
  }
}
.icon-location {
  &:before {
    content: $icon-location;
  }
}
.icon-hour {
  &:before {
    content: $icon-hour;
  }
}
.icon-integrations {
  &:before {
    content: $icon-integrations;
  }
}
.icon-linux {
  &:before {
    content: $icon-linux;
  }
}
.icon-logo {
  &:before {
    content: $icon-logo;
  }
}
.icon-features {
  &:before {
    content: $icon-features;
  }
}
.icon-wi-fi {
  &:before {
    content: $icon-wi-fi;
  }
}
.icon-rooms {
  &:before {
    content: $icon-rooms;
  }
}
.icon-organization {
  &:before {
    content: $icon-organization;
  }
}
.icon-labs {
  &:before {
    content: $icon-labs;
  }
}
.icon-hosting {
  &:before {
    content: $icon-hosting;
  }
}
.icon-cogs {
  &:before {
    content: $icon-cogs;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-customcontent-o {
  &:before {
    content: $icon-customcontent-o;
  }
}
