@import '~styles/_variables.scss';

.ant-btn.app-btn-plain {
  background-color: #0041ff;
  border-radius: 0;
  height: auto;
  padding: 8px 15px;
  min-width: 140px;
}

// when antd is upgraded to >= 3.17, this can be removed,
// since antd supports type="link" in this version.
// See https://3x.ant.design/components/button/
.ant-btn.ant-btn-link {
  color: $color-blue;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    border-color: transparent;
    background-color: transparent;
  }

  &.ant-btn-clicked:after {
    animation: none;
  }
}
