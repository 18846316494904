@import '~antd/dist/antd.less';
@import '~antd/lib/style/themes/default.less';
@import '~antd/lib/style/v2-compatible-reset.less';

@font-family: 'Noto Sans', 'Chinese Quote', -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
  'Helvetica Neue', Helvetica, Arial, sans-serif;

// @font-size-base  : 14px;
@text-color: #222;
@heading-color: #333;

// path relative to /node_modules/antd/lib/style/core/index.less
@icon-url: '../../../../../src/styles/fonts/iconfont';

// Layout
@layout-body-background: #fff;
@layout-header-background: #fff;

// colors
// @blue-6: #2d7f88;
@blue-6: #0000fe;
@success-color: @green-6;
@primary-1: #eef5f6;

@border-radius-base: 0;
@border-radius-sm: 0;

.ant-alert-warning {
  border: 1px solid mix(#8fa9ff, white, 25%);
  background-color: mix(#e6e6ff, white, 25%);
}

.ant-alert-warning .ant-alert-icon {
  color: @blue-6;
}

// Override the above fix just for the login.html alerts
.ant-alert-warning-original {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}

.ant-alert-warning-original .ant-alert-icon {
  color: #faad14;
}

.ant-radio-group {
  .ant-radio-button-wrapper-checked {
    background-color: @blue-6;
    color: #fff;
  }
}
.ant-radio-group-vertical {
  line-height: @line-height-base;

  .ant-radio-wrapper {
    display: block;
  }
}

// fix empty message in alert
.ant-alert.ant-alert-with-description {
  .ant-alert-message:empty {
    display: none;
  }
}

.ant-alert-with-description {
  margin-bottom: 20px;
}

// fix card empty body style
.ant-card-body:empty {
  display: none;
}

.ant-card-head {
  line-height: 48px;
}

.ant-card-head-title {
  padding: 0 0;
}

// support input in button group
.ant-btn-group > .ant-form-item {
  display: inline-block;
}

// large variant for upload
.ant-upload-large .ant-btn {
  line-height: 40px;
  height: auto;
}

.ant-btn-group > .ant-form-item {
  margin-bottom: 0;
}

// fix alert backgrounds
.ant-alert-success {
  border-color: #cfefdf;
  background-color: lighten(@blue-6, 80%);
  background-color: #e7f0f7;
  border-color: #e8e8e8;
}

// Override the above fix just for the login.html alerts
.ant-alert-success-original {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.ant-alert-info {
  border-color: #d7e7e9;
  background-color: #e7f0f7;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e7f0f7;
}

// fix margin top for inline menu
.ant-menu-inline .ant-menu-item {
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
}

.ant-popover {
  .ant-popover-arrow,
  .ant-popover-inner {
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
  }

  &.ant-popover-placement-left,
  &.ant-popover-placement-leftTop,
  &.ant-popover-placement-leftBottom {
    .ant-popover-arrow {
      border-right: 1px solid black;
      border-top: 1px solid black;
    }
  }

  &.ant-popover-placement-right,
  &.ant-popover-placement-rightTop,
  &.ant-popover-placement-rightBottom {
    .ant-popover-arrow {
      border-left: 1px solid black;
      border-bottom: 1px solid black;
    }
  }

  &.ant-popover-placement-top,
  &.ant-popover-placement-topLeft,
  &.ant-popover-placement-topRight {
    .ant-popover-arrow {
      border-bottom: 1px solid black !important;
      border-right: 1px solid black !important;
    }
  }

  &.ant-popover-placement-bottom,
  &.ant-popover-placement-bottomLeft,
  &.ant-popover-placement-bottomRight {
    .ant-popover-arrow {
      border-top: 1px solid black !important;
      border-left: 1px solid black !important;
    }
  }

  .ant-popover-inner {
    border: 1px solid black;
  }
}
