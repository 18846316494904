@import '~styles/_variables.scss';

.ant-layout-header {
  .container {
    display: flex;
    ul {
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.layout-main {
  &-kiosk {
    padding-top: 1.5rem;
  }

  &.layout-no-notifications > .ant-layout-content {
    margin-top: $navigation-height;
  }

  .ant-layout-header .ant-menu-item {
    .icon {
      font-size: 1.5em;
      vertical-align: middle;
      margin-right: 5px;
    }
    svg {
      font-size: 1.5em;
      vertical-align: middle;
      font-weight: normal;
      margin-right: 5px;
      path {
        transition: fill 0.3s;
        fill: #222;
      }
    }

    &-selected {
      svg {
        path {
          fill: #0000fe;
        }
      }
    }

    &:hover {
      svg {
        path {
          fill: #0000fe;
        }
      }
    }
  }
}

.notifications.beta-offset{
  margin-top: 0;
}

.notifications {
  margin-top: $navigation-height;

  .banner-base {
    font-size: 18px;
    line-height: 2.5em;
    display: block;
    transition: 0.3s all ease-out;
  }

  .banner-nonpaying {
    background-color: $color-light-red;
    color: #fff;
  }

  .banner-intrial {
    background-color: orange;
    color: #fff;
  }

  .banner-info {
    background-color: $color-blue;
    color: #fff;
  }

  .banner-inner {
    max-width: 1080px;
    width: 80%;
    display: block;
    margin: auto;
    text-align: center;

    .anticon {
      margin-right: 20px;
    }

    a {
      color: white;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  > .ant-layout-header {
    height: ($navigation-height + 45px);
  }

  .anticon {
    &.light-red {
      color: $color-light-red;
    }
  }
}
