@import './_variables.scss';

.list {
  .list-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .list-content-left {
      text-align: left;
      margin-right: 10px;
    }

    > .list-content-right {
      margin-left: 10px;
      text-align: right;
    }

    > .list-content-middle {
      flex: 1 0 auto;
    }

    > .list-content-picture {
      &.anticon,
      &.icon {
        font-size: 3em;
      }
    }
  }

  &ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  &ul {
    list-style-type: circle;
  }
}

.list-selectable {
  .list-content {
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background-color: #e7f0f7;
    }
  }
}

.list-status {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: end;
}

.list-horizontal {
  > * {
    display: inline-block;
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.list-vertical {
  &ul,
  &ol {
    list-style-type: none;
  }
}

.list-comma {
  > li {
    &:after {
      content: ',';
    }

    &:last-child:after {
      content: '';
    }
  }
}
