.auth-signup {
  @include main-content-wrapper;

  &-actions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &-content {
    @include main-content($auth-background-color-primary);

    &-title {
      text-align: center;
      padding: $spacing-large 0;
      padding-top: 0;
      background-color: transparent;
    }

    .auth-signup-form {
      padding: $spacing-large;

      &-providers {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: stretch;

        & > :not(:last-child) {
          margin-bottom: $spacing;
        }
      }

      &-fields {
        form {
          display: flex;
          flex-direction: column;

          justify-content: stretch;

          .auth-signup-form-fields-field {
            margin-bottom: $spacing;

            label {
              margin-bottom: calc($spacing / 2);

              &.checkbox-label {
                margin-bottom: 0;
                font-weight: 400;

                span {
                  font-size: $auth-small-font-size;
                  color: $auth-normal-font-color;
                }
              }
              &.checkbox-label a {
                font-size: $auth-small-font-size;
                color: $auth-primary-color;
              }
            }
          }
        }

        &-field {
          label {
            display: inline-block;
            font-weight: 900;
            color: $auth-normal-font-color;
            font-size: $auth-small-font-size;
          }

          .checkbox-label-link {
            color: $auth-primary-color;
            display: inline;
          }
        }
      }
    }
  }

  @include respond(mobile) {
    .auth-signup-form {
      @include mobile-form;
    }
  }
}
