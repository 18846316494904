@import './_variables.scss';

// .ant-btn {
//   font-weight: 300 !important;
// }

.ant-checkbox-group {
  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-row:not(.ant-form-item) {
    margin-top: 0;
  }
}

.ant-checkbox-wrapper {
  .anticon {
    margin-left: 5px;
  }
  &.label-lg {
    font-size: $font-size-lg;
    padding-top: 10px;
  }
}

.ant-form-item-readonly {
  margin-bottom: 6px;
}

.ant-form-item-control-readonly {
  font-size: $font-size-md;
  line-height: 39px;
}

.form-lg {
  .ant-btn {
    font-size: $font-size-lg;
    padding: 10px 15px;
    height: auto;
  }

  // make labels bigger
  .ant-form-item-label {
    label {
      font-size: $font-size-lg;
      line-height: 42px;
    }
  }

  label.ant-form-item-label {
    font-size: $font-size-lg;
    line-height: 42px;
  }

  .ant-form-item-control-readonly {
    font-size: $font-size-lg;
    line-height: 42px;
  }

  .ant-form-item-readonly {
    margin-bottom: 12px;
  }
}

.ant-btn-lg {
  font-size: $font-size-lg;
  padding: 10px 15px;
  height: auto;
}

.form-strong {
  .ant-form-item-label {
    label {
      color: #000;
      font-weight: 500;
    }
  }

  label.ant-form-item-label {
    color: #000;
    font-weight: 500;
  }
}

.featureset-edit {
  .ant-form-item-control-wrapper {
    text-align: right;
  }

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .bordered-column {
    border-right: 1px solid $card-border-color;
    padding-right: 10px !important;
  }
}

.input-xlg {
  input,
  & {
    padding: 6px 7px;
    height: 42px;
    font-size: 24px;
  }

  .has-error.has-feedback:after,
  .has-success.has-feedback:after,
  .has-warning.has-feedback:after,
  .is-validating.has-feedback:after {
    padding: 6px 7px;
    height: 42px;
    // font-size: 24px;
  }
}

.text-center {
  input,
  textarea,
  select,
  button {
    text-align: center;
  }
}

.input-weekdays {
  .ant-form-item-label {
    vertical-align: top;
  }
  .ant-checkbox-group-item {
    text-align: center;

    span:last-child {
      display: block;
    }
  }
}

// make link consistent when displaying muted text
.ant-form-extra,
.text-mute {
  a,
  &a {
    color: #b3b3b3;
    text-decoration: underline;
  }
}
