.app-mask {
  position: relative;

  .app-mask-drop {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: default;

    /* should be less than sticky header z-index,
    to avoid covering the menu items.
    In current antd component, sticky header has 'z-index: 2;'  */
    z-index: 1;
  }
}

.MaskLock {
  display: inline-block;
  position: relative;
  z-index: 2;

  &-small {
    width: 1rem;
    height: 1rem;
    transform: translate(0px, -2px);
  }

  &-normal {
    width: 1.5rem;
    height: 1.5rem;
    transform: translate(0px, -3px);
  }

  &-large {
    width: 2rem;
    height: 2rem;
    transform: translate(0px, -4px);
  }

  img {
    width: 100%;
  }

  &-label {
    display: inline-block;
    margin-right: -1rem;
  }
}

.MaskDisclaimer {
  h6 {
    font-size: calc(14 / 16) * 1rem;
    margin-bottom: 0;
  }

  p {
    padding-top: 0;
    padding-bottom: 0;
  }

  .explanation {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  .unlock-cta {
    font-size: calc(12 / 16) * 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}
