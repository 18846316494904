.auth-background {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;

  &-image {
    background-repeat: no-repeat;
    position: absolute;
    background-size: cover;

    &-left {
      left: 0;
      bottom: 0;

      width: 25vw;
      min-width: 30rem;

      height: 21vh;
      min-height: 15rem;

      @media (max-width: 1500px) {
        left: -5%;
      }

      @media (max-width: 1400px) {
        left: -10%;
      }

      @media (max-width: 1200px) {
        left: -15%;
      }

      @media (max-width: 1100px) {
        left: -20%;
      }

      @include respond(tablet) {
        display: none;
      }
    }

    &-right {
      right: 0;
      bottom: 0;

      width: 17rem;
      height: 14rem;

      width: 18vw;
      min-width: 18rem;

      height: 20vh;
      min-height: 11rem;

      @media (max-width: 1100px) {
        right: -5%;
      }

      @include respond(tablet) {
        display: none;
      }
    }
  }
}
