$navigation-height: 64px;

$font-size-base: 14px;
$font-size-xs: $font-size-base * 0.7143;
$font-size-sm: $font-size-base * 0.85714;
$font-size-md: $font-size-base;
$font-size-lg: $font-size-base * 1.35714;
$font-size-xlg: $font-size-base * 1.71428;
$font-size-xxlg: $font-size-base * 2.5;
$font-weight-base: 400;

$row-margin: 20px;

/* colors */
// $color-blue: #2d7f88;
// $color-blue: #2f6897;
// $color-blue: #0000fe;
$color-blue: #0022e0;
$color-blue-light: lighten($color-blue, 10%);
$color-blue-lighter: lighten($color-blue, 20%);
// $color-blue-light: #5dc7d3;
// $color-blue-lighter: #d7eced;
$color-orange: #e08558;
$color-light-orange: #faad14;
$color-green: #00a854;
$color-red: #f04134;
$color-light-red: #de5d58;

$color-success: $color-green;
$color-warn: $color-light-orange;
$color-danger: $color-light-red;

$color-gray-darker: lighten(#000, 10%);
$color-gray-dark: lighten(#000, 30%);
$color-gray-light: lighten(#000, 50%);
$color-gray-lighter: lighten(#000, 70%);
$color-gray-lightest: lighten(#000, 96%);
$color-gray: #f5f5f5;
$color-white: #ffffff;
$color-black: #000000;

$card-hover-color: #f6f6f6;
$card-border-color: #e9e9e9;
$card-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);

// Media queries breakpoints
// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / tablet
$screen-sm: 768px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 992px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;

// Extra Large screen / full hd
$screen-xl: 1600px;
$screen-xl-min: $screen-xl;

// provide a maximum
$screen-xs-max: ($screen-sm-min - 1px);
$screen-sm-max: ($screen-md-min - 1px);
$screen-md-max: ($screen-lg-min - 1px);
$screen-lg-max: ($screen-xl-min - 1px);

// mixins
@mixin clearfix {
  zoom: 1;
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
    visibility: hidden;
    font-size: 0;
    height: 0;
  }
}
